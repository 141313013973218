<template>
	<div class="pui-form">
		<wastenotificationitems-modals :modelName="modelName"></wastenotificationitems-modals>

		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :title="$t('wastenotificationitems.delivered')">
						<v-row dense style="margin-top: -5px">
							<!-- WASTETYPE -->

							<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
								<pui-select
									v-if="isCreatingElement"
									:id="`wastetype-wastenotificationitems`"
									:attach="`wastetype-wastenotificationitems`"
									:label="$t('wastenotificationitems.wastetype')"
									:disabled="formDisabled"
									toplabel
									clearable
									modelName="vlupmwastetypes"
									v-model="model"
									reactive
									required
									:modelFormMapping="{ code: 'wastetype' }"
									:itemsToSelect="wastetypeItemsToSelect"
									:itemValue="['code']"
									:itemText="(item) => `${item.code} ` + ` , ` + `${item.typedescription}`"
									:fixedFilter="filterByNotification"
								></pui-select>
								<pui-select
									v-if="!isCreatingElement"
									:id="`wastetype-wastenotificationitems`"
									:attach="`wastetype-wastenotificationitems`"
									:label="$t('wastenotificationitems.wastetype')"
									disabled
									toplabel
									clearable
									modelName="vlupmwastetypeswithoutfilter"
									v-model="model"
									reactive
									required
									:modelFormMapping="{ code: 'wastetype' }"
									:itemsToSelect="wastetypeItemsToSelect"
									:itemValue="['code']"
									:itemText="(item) => `${item.code} ` + ` , ` + `${item.typedescription}`"
									:fixedFilter="filterByNotification"
								></pui-select>
							</v-col>

							<!-- QUANTITY -->
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
								<pui-number-field
									:id="`wastetype-wastenotificationitems`"
									v-model="model.quantity"
									:label="$t('wastenotificationitems.quantity')"
									:disabled="formDisabled"
									toplabel
									required
									suffix="m3"
								></pui-number-field>
							</v-col>
							<!-- ($v) => $v === null || $v >= 1 || ($v.indexOf(',') && /(?<!-),\d+/.test(String($v))) || 'Minimum value 1' -->
							<!-- INDTOTALQUANTITY -->
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
								<pui-select
									:id="`indtotalquantity-wastenotificationitems`"
									:attach="`indtotalquantity-wastenotificationitems`"
									:label="$t('wastenotificationitems.indtotalquantity')"
									:disabled="formDisabled"
									toplabel
									clearable
									required
									:items="codes"
									v-model="model"
									reactive
									:itemsToSelect="indtotalquantityItemsToSelect"
									:modelFormMapping="{ value: 'indtotalquantity' }"
								></pui-select>
							</v-col>
						</v-row>
					</pui-field-set>
					<pui-field-set :title="$t('wastenotificationitems.onboard')" v-if="this.model.indtotalquantity == '0'">
						<v-row dense style="margin-top: -5px"
							><!-- MAXSTORAGECAPACITY -->
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-number-field
									:id="`maxstoragecapacity-wastenotificationitems`"
									v-model="model.maxstoragecapacity"
									:label="$t('wastenotificationitems.maxstoragecapacity')"
									:disabled="formDisabled"
									toplabel
									suffix="m3"
									min="1"
								></pui-number-field>
							</v-col>
							<!-- QUANTITYRETAINED -->
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-number-field
									:id="`quantityretained-wastenotificationitems`"
									v-model="model.quantityretained"
									:label="$t('wastenotificationitems.quantityretained')"
									:disabled="formDisabled"
									toplabel
									suffix="m3"
									min="1"
								></pui-number-field>
							</v-col>
							<!-- REMAININGDELIVERYCOUNTRY -->
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-select
									:id="`remainingdeliveryport-wastenotificationitems`"
									:attach="`remainingdeliveryport-wastenotificationitems`"
									:label="$t('wastenotificationitems.remainingdeliverycountry')"
									:disabled="formDisabled"
									toplabel
									clearable
									v-model="model"
									modelName="mports"
									singleItemAutoselect
									:modelFormMapping="{ countrycode: 'remainingdeliverycountry', portcode: 'remainingdeliveryport' }"
									:itemsToSelect="remainingdeliverycountryItemsToSelect"
									:itemValue="['countrycode', 'portcode']"
									:itemText="(item) => `${item.locode} - ${item.portdescription}`"
								></pui-select>
							</v-col>
							<!-- ESTIMATEDTOBEGENERATED -->
							<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-number-field
									:id="`estimatedtobegenerated-wastenotificationitems`"
									v-model="model.estimatedtobegenerated"
									:label="$t('wastenotificationitems.estimatedtobegenerated')"
									:disabled="formDisabled"
									toplabel
									suffix="m3"
									min="1"
								></pui-number-field>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer>
				<pui-form-footer-btns
					:formDisabled="!havePermissions(['WRITE_WASTENOTIFICATIONITEMS'])"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'wastenotificationitems-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'wastenotificationitems',
			parentModelName: 'wastenotifications',
			listwasteitems: [],
			codes: [
				{
					text: this.$t('wastenotificationitems.yes'),
					value: '1'
				},
				{
					text: this.$t('wastenotificationitems.no'),
					value: '0'
				}
			]
		};
	},
	mounted() {},
	methods: {
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		afterGetData() {
			// Do something after get data from server

			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);

			this.model.notificationid = parentModel.pk.notificationid;
		}
	},
	computed: {
		wastetypeItemsToSelect() {
			return [{ code: this.model.wastetype }];
		},
		indtotalquantityItemsToSelect() {
			return [{ value: this.model.indtotalquantity }];
		},
		remainingdeliverycountryItemsToSelect() {
			return [{ countrycode: this.model.remainingdeliverycountry, portcode: this.model.remainingdeliveryport }];
		},
		filterByNotification() {
			return {
				rules: [
					{
						field: 'notificationid',
						op: 'eq',
						data: this.model.notificationid
					}
				]
			};
		}
	},
	created() {}
};
</script>
